import { Box } from '@mui/material';
import { theme } from '@portals/core/src/themes/sde/main';
import React from 'react';

export const PartnerLogo: React.FC<{ imgSrc: string }> = ({ imgSrc }) => {
  return (
    <Box
      height="40px"
      width="40px"
      borderRadius="6px"
      display="flex"
      left={theme.spacing(3)}
      bottom={theme.spacing(3)}
      position="absolute"
      bgcolor="rgba(255,255,255,1)"
      zIndex="1"
      sx={{
        overflow: 'hidden',
        backgroundImage: `url(${imgSrc})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    />
  );
};

PartnerLogo.displayName = 'PartnerLogo';
