import { Box, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import React from 'react';

import { getStyles } from './Marker.styles';
import { Stroke } from './Stroke';

type alignment = 'left' | 'right';

interface Props {
  label: JSX.Element;
  positionInPercent: number;
  labelAlignment: alignment;
}

export default function Marker({ label, positionInPercent, labelAlignment }: Props): React.ReactElement {
  const theme = useTheme<Theme>();
  const styles = getStyles(theme);

  const getMarkerTranslation = () => {
    if (positionInPercent === 100) {
      return 'translate(-100%, 0%)';
    }
    if (positionInPercent < 1) {
      return 'translate(-1px, 0%)';
    }
    return 'translate(-50%, 0%)';
  };

  const getLabelTranslation = () => {
    if (labelAlignment === 'left' && positionInPercent > 1) {
      return 'translate(-3%, -150%)';
    }
    if (labelAlignment === 'right' && positionInPercent < 100) {
      return 'translate(3%, -150%)';
    }
    return 'translate(0%, -150%)';
  };

  const getLabelPosition = () => {
    const leftPosition = { left: `${positionInPercent}%` };
    const rightPosition = { right: `${100 - positionInPercent}%` };

    if (labelAlignment === 'right') {
      return rightPosition;
    }

    return leftPosition;
  };

  return (
    <>
      <Box
        sx={{
          ...styles.marker,
          left: `${positionInPercent}%`,
          transform: getMarkerTranslation(),
        }}
      >
        {/* we have to use svg stroke because IE is not able to print background images by default (not even when using print-color-adjust) */}
        <Stroke sx={styles.stroke} />
      </Box>

      <Typography
        variant="button"
        component="div"
        color={theme.palette.secondary.dark}
        sx={{
          ...styles.label,
          ...getLabelPosition(),
          transform: getLabelTranslation(),
          textAlign: labelAlignment,
        }}
        className="enery-consumption-value"
      >
        <b>{label}</b>
      </Typography>
    </>
  );
}

Marker.displayName = 'Marker';
