import { Menu, MenuItem } from '@mui/material';
import { TextField } from '@portals/core/src/components/TextField/TextField';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';

export interface CustomSelectInputProps {
  value?: string;
  standardOptions?: any;
  label?: string;
  name: string;
  form: any;
}

export const CustomSelectInput = (props: CustomSelectInputProps) => {
  const prepareStandardOptions = (options: any): any => {
    const resultOptions = [];

    for (const optionsKey in options) {
      resultOptions.push({
        id: optionsKey,
        label: options[optionsKey],
      });
    }

    return resultOptions;
  };

  const value = props.value ?? '';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const standardOptions = props.standardOptions ? prepareStandardOptions(props.standardOptions) : [];
  const label = props.label ?? '';
  const { setFieldValue } = useFormikContext();

  const updateValue = (val: string) => {
    setFieldValue(props.name, val);
  };

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        updateValue(event.target.value);
        handleClose();
      }
      event.stopPropagation();
    },
    [handleClose, updateValue]
  );

  return (
    <div>
      <TextField onClick={handleOpen} fullWidth label={label} type="single" value={value} variant="standard" />
      <Menu
        anchorEl={anchorEl}
        open={open}
        disableAutoFocusItem={false}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {standardOptions.map((standardOption) => (
          <MenuItem
            key={standardOption.id}
            /* eslint-disable-next-line react/jsx-no-bind */
            onClick={() => {
              updateValue(standardOption.id);
              handleClose();
            }}
          >
            {standardOption.label}
          </MenuItem>
        ))}
        <MenuItem>
          <TextField type="single" onKeyDown={handleKeyDown} />
        </MenuItem>
      </Menu>
    </div>
  );
};

CustomSelectInput.displayName = 'CustomSelectInput';
