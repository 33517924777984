import { useTheme } from '@mui/material';
import type { Theme } from '@portals/core/src/themes/themes';
import { ChevronLeft, ChevronRight } from '@portals/icons';
import React, { useCallback, useMemo, useState } from 'react';
import MaterialUiCarousel from 'react-material-ui-carousel';
import { StyledIconButton } from 'react-material-ui-carousel/dist/components/Styled';
import type { CarouselProps as MaterialCarouselProps } from 'react-material-ui-carousel/dist/components/types';

interface CarouselProps {
  animation?: 'slide' | 'fade';
  children: any[];
  preloadCount?: number; // use 0 to disable preload, min is 3
  sx?: MaterialCarouselProps['sx'];
}

export const Carousel: React.FC<CarouselProps> = ({ children, preloadCount = 3, animation = 'slide', ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [childListLimit, setChildListLimit] = useState(preloadCount);
  const theme = useTheme<Theme>();

  const handleCarouselChange = useCallback(
    (now: number) => {
      if (!childListLimit) {
        return;
      }
      const preloadLimit = now + 2;
      if (now > currentIndex) {
        setTimeout(() => {
          setCurrentIndex(now);
          if (preloadLimit > childListLimit) {
            setChildListLimit(preloadLimit);
          }
        }, 300);
      }
    },
    [currentIndex, childListLimit]
  );

  const filteredChildren = useMemo(() => {
    if (!childListLimit) {
      return children;
    }
    return children.filter((child, key) => key < childListLimit);
  }, [children, childListLimit]);

  const navButton = useCallback(({ onClick, className, style, next, prev }) => {
    return (
      <StyledIconButton
        $alwaysVisible={false}
        $fullHeightHover={true}
        /* eslint-disable-next-line react/jsx-no-bind */
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }}
        className={className}
        style={style}
      >
        {next && <ChevronRight />}
        {prev && <ChevronLeft />}
      </StyledIconButton>
    );
  }, []);

  return (
    <MaterialUiCarousel
      index={currentIndex}
      onChange={handleCarouselChange}
      animation={animation}
      duration={200}
      indicators={false}
      autoPlay={false}
      strictIndexing={true}
      cycleNavigation={false}
      navButtonsProps={{
        style: {
          borderRadius: '100%',
          backgroundColor: '#FFFFFF',
          color: theme.palette.grey[900],
          border: 'none',
        },
      }}
      NavButton={navButton}
      {...props}
    >
      {filteredChildren}
    </MaterialUiCarousel>
  );
};

Carousel.displayName = 'Carousel';
