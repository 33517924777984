import { Box, SxProps } from '@mui/material';
import React, { useCallback, useRef } from 'react';

import { useDragAndScroll } from '../../hooks';
import { styles } from './HorizontalSwipeBar.styles';

interface HorizontalSwipeBarProps {
  children: React.ReactNode;
}

export const HorizontalSwipeBar: React.FC<HorizontalSwipeBarProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { handleMouseDown, handleMouseMove, handleMouseExit, isDragging, draggingOccurred } = useDragAndScroll({
    ref: containerRef,
  });

  const stopClickPropagation = useCallback(
    (e) => {
      if (draggingOccurred) {
        e.stopPropagation();
      }
    },
    [draggingOccurred]
  );

  return (
    <Box
      ref={containerRef}
      sx={
        {
          ...styles.outerBox,
          ...(isDragging && styles.outerBoxDragging),
        } as SxProps
      }
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseExit}
      onMouseLeave={handleMouseExit}
      onMouseMove={handleMouseMove}
      onClickCapture={stopClickPropagation}
    >
      <Box sx={styles.innerBox}>
        <Box sx={styles.scrollContainer}>{children}</Box>
      </Box>
    </Box>
  );
};

HorizontalSwipeBar.displayName = 'HorizontalSwipeBar';
