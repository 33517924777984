import { Box, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

import { getStyles } from './Scale.styles';

interface Props {
  scaleNumbers: number[];
  calculatePercent: (value: number) => number;
}

export default function Scale({ scaleNumbers, calculatePercent }: Props): React.ReactElement {
  const theme = useTheme();
  const styles = getStyles(theme);

  const renderScale = scaleNumbers.map((scaleNumber) => {
    return (
      <Typography
        key={`scale_number_${scaleNumber}`}
        variant="body2"
        component="span"
        sx={{
          ...styles.scale,
          left: `${calculatePercent(scaleNumber)}%`,
          transform: scaleNumber > 0 ? 'translateX(-50%)' : 'translateX(0%)',
        }}
        className="energy-consumption-scale"
      >
        {scaleNumber}
      </Typography>
    );
  });

  return (
    <Box sx={styles.container}>
      {renderScale}
      <Typography variant="body2" component="span" sx={styles.scaleLastChild} className="energy-consumption-scale">
        +
      </Typography>
    </Box>
  );
}

Scale.displayName = 'Scale';
