import { MarketingType, ObjectType, UsageType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { TFunction } from 'i18next';
import { number, string } from 'yup';

import { getMarketingTypeObjectTypeLabel } from '../../utils';
import { EstateSearchMainFormProps } from './EstateSearch.types';

export const getDefaultValues = (t: TFunction, perimeter?: number): EstateSearchMainFormProps => ({
  zipCityEstateId: '',
  perimeter: perimeter ?? 10,
  marketingType: MarketingType.BUY,
  lastClickedMarketingTypeValue: MarketingType.BUY,
  objectType: ObjectType.FLAT,
  usageType: UsageType.RESIDENTIAL,
  marketingTypeObjectTypeTextfieldValue: getMarketingTypeObjectTypeLabel(t, MarketingType.BUY, ObjectType.FLAT),
  maxPrice: undefined,
  maxMarketValue: undefined,
  maxRent: undefined,
  maxLease: undefined,
  minLivingSpace: undefined,
  minNumberRooms: undefined,
  minTotalSpace: undefined,
  minPropertySize: undefined,
});

export const getBasicValidationSchema = (t: TFunction) => ({
  perimeter: number().integer().min(0).max(999999999).nullable(),
  zipCityEstateId: string().strict().min(1).required(t('estateSearch.errorRequiredWhere')),
});
