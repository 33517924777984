import { mapZipCityEstateIdToCollection } from '@portals/sip-client-data/src/general/ApiClientMapping/ApiClientMapping';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import React, { memo, useCallback, useState } from 'react';

import { Autocomplete, type AutocompleteProps } from '../../../components';
import { mapZipCityEstateCollectionToSingleString } from '../../../utils';

interface Props {
  fieldName: string;
}

const AutoCompleteWrapper = memo((props: AutocompleteProps) => {
  return <Autocomplete {...props} />;
});
AutoCompleteWrapper.displayName = 'AutoCompleteWrapper';

export const ZipCityEstateIdAutocomplete: React.FunctionComponent<Props> = ({ fieldName }: Props) => {
  const { values, errors, setFieldTouched, setFieldValue } = useFormikContext();
  const [nothingSelected, setNothingSelected] = useState(false);

  const preSelectedOptions = mapZipCityEstateIdToCollection(get(values, fieldName, ''));

  /*eslint-disable react-hooks/exhaustive-deps*/
  const onChange = useCallback((options) => {
    const value = mapZipCityEstateCollectionToSingleString(options);
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, value, true);
  }, []);

  const onNothingSelected = useCallback((searchIsEmpty: boolean) => {
    setNothingSelected(!searchIsEmpty);
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, '', true);
  }, []);

  const textFieldProps = {
    error: nothingSelected ? Boolean(errors[fieldName as keyof typeof errors]) : false,
    helperText: nothingSelected ? errors[fieldName as keyof typeof errors] : '',
    sx: {
      '& .MuiAutocomplete-inputRoot': {
        borderRightWidth: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  };

  return (
    <AutoCompleteWrapper
      preSelectedOptions={preSelectedOptions}
      onSelectedOptions={onChange}
      textFieldProps={textFieldProps}
      onNothingSelected={onNothingSelected}
    />
  );
};

ZipCityEstateIdAutocomplete.displayName = 'ZipCityEstateIdAutocomplete';
