import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  const container = {
    paddingTop: `calc(12px + ${theme.spacing(4)})`,
    [theme.breakpoints.up('lg')]: {
      paddingTop: `calc(26px + ${theme.spacing(6)})`,
    },
  };

  const spectrum = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '40px',
    marginY: theme.spacing(1),
    height: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      marginY: theme.spacing(3),
      height: theme.spacing(7),
    },
    '.color-range-background': {
      width: '100%',
      height: '100%',
      borderRadius: 'inherit',
    },
  };

  const legend = {
    color: theme.palette.grey[800],
    textAlign: 'right',
    marginTop: theme.spacing(3),
    fontSize: '8px',
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(4),
      fontSize: 'initial',
    },
  };

  return {
    container,
    spectrum,
    legend,
  };
};
