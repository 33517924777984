import { Box, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import React from 'react';

import { getStyles } from './Label.styles';
import { LabelCircle } from './LabelCircle';

interface Props {
  label: string;
  highlighted: boolean;
  positionLeftInPercent: number;
  positionRightInPercent: number;
}

export default function Label({
  label,
  highlighted,
  positionLeftInPercent,
  positionRightInPercent,
}: Props): React.ReactElement {
  const theme = useTheme<Theme>();
  const styles = getStyles(theme);

  return (
    <Box
      key={`efficiency_classname_box_${label}`}
      component="div"
      sx={styles.container}
      left={`${positionLeftInPercent}%`}
      right={`${positionRightInPercent}%`}
      width={`${(positionRightInPercent - positionLeftInPercent).toFixed(2)}%`}
    >
      {
        /*  we have to use svg stroke because IE is not able to print background images by default (not even when using print-color-adjust)*/
        highlighted && <LabelCircle sx={styles.highlighted} />
      }
      <Typography variant="button" component="div" sx={styles.label} className="energy-consumption-label">
        {label}
      </Typography>
    </Box>
  );
}

Label.displayName = 'Label';
