import { useEffect, useState } from 'react';

export type onTouchInputFieldProps = {
  onTouchStart: () => void;
  onTouchEnd: () => void;
};

export const usePreventSafariAutozoom = (): onTouchInputFieldProps => {
  const [inputFieldProps, setInputFieldProps] = useState({} as onTouchInputFieldProps);

  useEffect(() => {
    const isIPhoneOrIPad = /iPhone|iPad/i.test(navigator.userAgent);

    if (isIPhoneOrIPad) {
      const viewport = document.querySelector('meta[name=viewport]') as HTMLElement;

      const disableAutozoom = () => {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
      };

      const enableAutozoom = () => {
        setTimeout(function () {
          viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
        }, 500);
      };

      setInputFieldProps({
        onTouchStart: disableAutozoom,
        onTouchEnd: enableAutozoom,
      });
    }
  }, []);

  return inputFieldProps;
};
