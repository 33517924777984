import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  const container = {
    position: 'relative',
    height: '12px',
    [theme.breakpoints.up('lg')]: {
      height: '26px',
    },
  };

  const scale = {
    color: theme.palette.text.secondary,
    position: 'absolute',
    fontSize: '8px',
    [theme.breakpoints.up('lg')]: {
      fontSize: 'initial',
    },
  };

  const scaleLastChild = Object.assign({}, scale, { display: 'block', right: 0, left: 'initial' });

  return {
    container,
    scale,
    scaleLastChild,
  };
};
