import { Box } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Filter } from '@portals/icons';
import { useTranslation } from 'next-i18next';
import React from 'react';

export interface Props extends ButtonProps {
  numberOfActiveFilters: number;
  onClick: ButtonProps['onClick'];
}

export const CustomizeSearchButton: React.FunctionComponent<Props> = ({
  numberOfActiveFilters,
  onClick,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      color="secondary"
      startIcon={
        <Box
          bgcolor="#000000"
          borderRadius="50%"
          width={{ xs: 20, lg: 24 }}
          height={{ xs: 20, lg: 24 }}
          color="#FFFFFF"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontSize={{ xs: 15, lg: 18 }}>{numberOfActiveFilters}</Typography>
        </Box>
      }
      endIcon={<Filter />}
      onClick={onClick}
      sx={{
        whiteSpace: 'nowrap',
      }}
      {...props}
    >
      <Box display={{ xs: 'none', lg: 'inherit' }}>{t('customizeSearch')}</Box>
      <Box display={{ xs: 'inherit', lg: 'none' }}>{t('customizeSearchSmall')}</Box>
    </Button>
  );
};

CustomizeSearchButton.displayName = 'CustomizeSearchButton';
