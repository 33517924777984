import { EstateListItemProps, EyeCatcherProps, MainFactProps } from '@portals/core-immobilien/src/components';
import { Option as RadioInputButtonOption } from '@portals/forms/src/components/RadioInputButton/RadioInputButton';
import { Option as SelectOption } from '@portals/forms/src/components/SelectInput/SelectInput';
import { InfoCircleOutline as InfoCircleOutlineIcon } from '@portals/icons';
import { isArray, omitBy } from 'lodash-es';
import { i18n } from 'next-i18next';

import { EstateAdItem } from '../components/UserDashboard/EstateAdList/EstateAdList';
import { AvailablenessState, Option as EstateAdOption } from '../config/types/estateAd';
import { EstateAdProps } from '../config/types/estateAdProps';

interface RadioOption extends RadioInputButtonOption {
  text: string;
}

export const today = new Date().toJSON().slice(0, 10);
export const notSpecifiedOptionValue = 'not_specified';

const numbersToSelectOption = (n: number): SelectOption => {
  return { id: `${n}`, text: `${n}` };
};

export const createNumberOptions = (n: number): SelectOption[] => {
  return Array.from({ length: n }, (_, i) => i + 1).map(numbersToSelectOption);
};

export const toSelectOptions = (option: EstateAdOption): SelectOption => {
  return { id: `${option.value}`, text: `${option.label}` };
};

export const toRadioOptions = (option: EstateAdOption, idx: number): RadioOption => {
  return { id: `${idx}`, text: option.value, label: option.label };
};

export const deleteNotNeededKeys = (estateAdProps: EstateAdProps): EstateAdProps => {
  delete estateAdProps.isAvailableByArrangement;
  delete estateAdProps.isConstructionYearUnknown;
  delete estateAdProps.hasParkingSpaces;
  delete estateAdProps.isEstatePriceIncluded;
  delete estateAdProps.energypassAvailableness;
  return estateAdProps;
};

export const removeEmptyOrNotSpecifiedProps = (estateAdProps: EstateAdProps): EstateAdProps => {
  return omitBy(estateAdProps, (value) => {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      (isArray(value) && value.length === 0) ||
      value === notSpecifiedOptionValue
    );
  });
};

export const prepareEstateAdPropsForApi = (estateAdProps: EstateAdProps, shouldBePublished: boolean): EstateAdProps => {
  if (estateAdProps.isAvailableByArrangement === true) {
    estateAdProps.settingAvailableFrom = AvailablenessState.BY_ARRANGEMENT;
  }

  estateAdProps.publish = shouldBePublished;

  const reducedProps = removeEmptyOrNotSpecifiedProps(estateAdProps);
  return deleteNotNeededKeys(reducedProps);
};

export const priceFormatter = (price: string): string => {
  return price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
};

export const getMainfacts = (estateAdData: EstateAdItem): MainFactProps[] => {
  i18n?.init();

  const mainfacts: Array<MainFactProps> = [];

  if (estateAdData.spaceLiving) {
    mainfacts.push({
      type: 'size',
      label: `${estateAdData.spaceLiving} ${i18n?.t('forms.estateAd.squareMeter.unit')}`,
    });
  }

  if (estateAdData.spaceProperty) {
    mainfacts.push({
      type: 'size',
      label: `${estateAdData.spaceProperty} ${i18n?.t('forms.estateAd.squareMeter.unit')}`,
    });
  } else if (estateAdData.numRoom) {
    mainfacts.push({ type: 'roomCount', label: `${estateAdData.numRoom}` });
  }

  return mainfacts;
};

export const getEyecatcher = (
  estateAdData: EstateAdItem,
  t: (key: string, options?: Record<string, unknown>) => string
): EyeCatcherProps[] => {
  if (estateAdData.state === 'published') {
    return [
      {
        type: 'primary',
        label: t('userDashboard.estateAdList.status.liveSince', {
          publicationDate: new Date(estateAdData.modifiedDate).toLocaleDateString(),
        }),
        icon: InfoCircleOutlineIcon,
      },
    ];
  }
  return [{ type: 'dark', label: t(`userDashboard.estateAdList.status.${estateAdData.state}`) }];
};

export const mapFormDataToEstateTeaserProps = (estateAdData: EstateAdItem): EstateListItemProps => {
  return {
    id: estateAdData?.userEstateId,
    title: estateAdData?.textEstateHeading,
    subtitle: estateAdData?.geoCity,
    images: estateAdData?.images.map((img) => {
      return img.m;
    }),
    price: priceFormatter(estateAdData.priceBuy),
    eyeCatcher: [
      {
        type: 'dark',
        label: 'Privatinserat',
      },
    ],
    mainFacts: getMainfacts(estateAdData),
  };
};

export const storeEstateAdItem = (estateAd: EstateAdItem): void => {
  const storageKey = 'estate-ad-form-data';
  sessionStorage.setItem(storageKey, JSON.stringify(estateAd));
};

export const getStoredEstateAdItem = (): EstateAdItem => {
  const storageKey = 'estate-ad-form-data';
  return JSON.parse(sessionStorage.getItem(storageKey));
};
