import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: 0,
        p: 0,
      },
    },
    closeButtonContainer: {
      textAlign: 'right',
      position: { lg: 'absolute' },
      right: { lg: 0 },
      top: 0,
      display: { lg: 'flex' },
      alignItems: 'center',
      height: { lg: '100%' },
    },
    closeButton: {
      position: { lg: 'absolute' },
      right: { lg: 0 },
      top: 0,
      display: { lg: 'none' },
      color: 'inherit',
    },
    closeIcon: {
      fontSize: 14,
    },
    closeIconButton: {
      position: { lg: 'absolute' },
      right: { lg: 0 },
      top: 0,
      display: { xs: 'none', lg: 'flex' },
      border: 'none',
      color: 'inherit',
      m: { xs: 8 },
      p: { xs: '15px' },
      '& svg': { width: theme.spacing(6), height: theme.spacing(6) },
    },
    navButton: {
      borderRadius: '100%',
      borderColor: theme.palette.grey['light'],
      color: theme.palette.grey['dark'],
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      width: { xs: '42px', lg: '54px' },
      height: { xs: '42px', lg: '54px' },
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      gap: { xs: 5, lg: 8 },
      pt: { xs: 0, lg: 8 },
      pb: { xs: 7, lg: 8 },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: { xs: theme.spacing(0), sm: theme.spacing(0, 11) },
    },
    imageContent: {
      maxWidth: '100%',
      objectFit: 'contain',
      maxHeight: { xs: 'calc(100vh - 230px)', lg: 'calc(100vh - 250px)' },
    },
    placeholderContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    placeholder: {
      height: {
        xs: `calc(100vh - 230px - ${theme.spacing(9)})`,
        lg: `calc(100vh - 250px - ${theme.spacing(9)})`,
      },
      maxHeight: '500px',
      width: '-webkit-fill-available',
      paddingX: { xs: 10, lg: 12 },
      paddingBottom: { xs: 5, lg: 8 },
    },
  };
};
