import { useCallback, useState } from 'react';

import { Props, States } from './useDragAndScroll.types';
import { validateProps } from './useDragAndScroll.utils';

export const useDragAndScroll = ({ ref, scrollSpeed = 1.5, minDragDistance = 10 }: Props): States => {
  const [isDragging, setIsDragging] = useState(false);
  const [startMouseX, setStartMouseX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [draggingOccurred, setDraggingOccurred] = useState(false);

  validateProps({ scrollSpeed, minDragDistance });

  const getCurrentMouseX = useCallback(
    (e: React.MouseEvent<HTMLElement>): number => {
      return e.pageX - (ref.current?.offsetLeft ?? 0);
    },
    [ref]
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setIsDragging(true);
      setStartMouseX(getCurrentMouseX(e));
      setScrollLeft(ref.current?.scrollLeft ?? 0);
      setDraggingOccurred(false);
    },
    [getCurrentMouseX, ref]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!isDragging) {
        return;
      }

      e.preventDefault();
      const mouseDistance = (getCurrentMouseX(e) - startMouseX) * scrollSpeed;
      if (Math.abs(mouseDistance) > minDragDistance) {
        setDraggingOccurred(true);
      }
      if (ref.current) {
        ref.current.scrollLeft = scrollLeft - mouseDistance;
      }
    },
    [isDragging, getCurrentMouseX, startMouseX, scrollSpeed, minDragDistance, ref, scrollLeft]
  );

  const handleMouseExit = useCallback(() => {
    setIsDragging(false);
  }, []);

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseExit,
    isDragging,
    draggingOccurred,
  };
};
