import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  const pill = {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.darker,
    cursor: 'inherit',
    boxShadow: 'none',
    padding: '4px 12px',
    [theme.breakpoints.up('lg')]: {
      padding: '8px 16px',
    },
    '.MuiChip-icon': {
      color: 'inherit',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      fontSize: theme.spacing(5),
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.spacing(6),
      },
    },
    '.MuiChip-labelSmall': { padding: 0 },
  };

  return {
    pill,
  };
};
