import { useNumberFormatter } from '@portals/forms/src/hooks/NumberFormatter/NumberFomatter';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

export type formatterFunction = (min: number | null, max: number | null, defaultLabel?: string) => string;

export function usePriceFormatter(): formatterFunction {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter({});

  return useCallback((min, max, defaultLabel) => {
    if (min === null && max === null) {
      return defaultLabel ? defaultLabel : '';
    }

    if (min !== null && max !== null) {
      return t('minMaxPrice', { min: numberFormatter(String(min)), max: numberFormatter(String(max)) });
    }

    if (min) {
      return t('minPrice', { min: numberFormatter(String(min)) });
    }

    if (max) {
      return t('maxPrice', { max: numberFormatter(String(max)) });
    }

    return defaultLabel ? defaultLabel : '';
  }, []);
}
