import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/immobilien/main';
import React from 'react';

export interface EstateItemHeaderProps {
  title: string;
  subtitle: string;
}

export const EstateItemHeader: React.FC<EstateItemHeaderProps> = ({ title, subtitle }) => (
  <>
    <Typography
      className="estate-item-header-subtitle"
      mb={3}
      component="span"
      variant="h6"
      sx={{ color: theme.palette.common.black }}
    >
      {subtitle}
    </Typography>
    <Typography
      className="estate-item-header"
      component="h3"
      variant="c3"
      sx={{
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: { xs: '2', sm: '1', md: '2' },
        WebkitBoxOrient: 'vertical',
        maxHeight: '2.7em',
      }}
    >
      {title}
    </Typography>
  </>
);

EstateItemHeader.displayName = 'EstateItemHeader';
