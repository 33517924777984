import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

export const Title: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Typography
      data-testid="estate-card-title"
      variant="h4"
      component="h4"
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: { xs: '2', sm: '3', md: '2' },
        WebkitBoxOrient: 'vertical',
        height: { xs: 'auto', sm: '72px', md: '48px', lg: '64px' },
        maxHeight: { xs: '48px', sm: '72px', md: '48px', lg: '64px' },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {title}
    </Typography>
  );
};

Title.displayName = 'Title';
