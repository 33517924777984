import { Box } from '@mui/material';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { theme } from '@portals/core/src/themes/immobilien/main';
import React, { useMemo } from 'react';

import { Carousel } from '../../../components';
import { AspectMedia } from '../AspectMedia/AspectMedia';
import { PartnerLogo } from '../PartnerLogo/PartnerLogo';

export interface ImageContainerProps {
  imageList: string[];
  title: string;
  partnerLogo: string;
  bookmarkButton?: React.ReactNode;
}

export const ImageContainer: React.FC<ImageContainerProps> = ({ imageList, partnerLogo, title, bookmarkButton }) => {
  const imageComponentList = useMemo(() => {
    return imageList.map((image, key) => {
      return <AspectMedia key={key} media={{ alternativeText: title, url: image }} aspectWidth={3} aspectHeight={2} />;
    });
  }, [imageList, title]);

  return (
    <Box
      className="estate-list-item-image-container"
      height={1}
      position="relative"
      width={1}
      zIndex="1"
      borderRadius="12px"
      bgcolor={theme.palette.grey[400]}
      marginRight={{ xs: 0, md: 4, lg: 8 }}
    >
      <Box
        position="absolute"
        width={1}
        height={1}
        display="flex"
        color={theme.palette.common.white}
        justifyContent="center"
        alignItems="center"
      >
        <LoadingDots />
      </Box>
      <Carousel
        sx={{
          borderRadius: '12px',
          aspectRatio: '3/2',
        }}
      >
        {imageComponentList}
      </Carousel>
      {partnerLogo && <PartnerLogo imgSrc={partnerLogo} />}
      {bookmarkButton && (
        <Box
          position="absolute"
          zIndex={1}
          right={9}
          bottom={9}
          borderRadius="50%"
          bgcolor={theme.palette.background.paper}
        >
          {bookmarkButton}
        </Box>
      )}
    </Box>
  );
};

ImageContainer.displayName = 'ImageContainer';
