import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

export interface CostProps {
  priceWithCurrency: string;
  costType?: string;
  paymentCycle?: string;
}

export const Cost: React.FC<CostProps> = ({ costType, priceWithCurrency, paymentCycle }) => {
  return (
    <>
      {costType && (
        <Typography variant="button" component="span">
          <b>{costType}</b>
        </Typography>
      )}

      <Typography variant="h3" component="span" marginLeft="auto">
        {priceWithCurrency}
      </Typography>

      {paymentCycle && (
        <Typography variant="body2" component="span">
          {paymentCycle}
        </Typography>
      )}
    </>
  );
};

Cost.displayName = 'Cost';
