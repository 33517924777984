import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

export type formatterFunction = (min: number | null, max: number | null, defaultLabel?: string) => string;

export function useRoomFormatter(): formatterFunction {
  const { t } = useTranslation();

  return useCallback((min, max, defaultLabel) => {
    if (min === null && max === null) {
      return defaultLabel ? defaultLabel : '';
    }

    if (min !== null && max !== null) {
      return t('minMaxRooms', { min, max });
    }

    if (min) {
      return t('minRooms', { min });
    }

    if (max) {
      return t('maxRooms', { max });
    }

    return defaultLabel ? defaultLabel : '';
  }, []);
}
