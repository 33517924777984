import { Pill, PillProps } from '@portals/core/src/components/Pill/Pill';
import { theme } from '@portals/core/src/themes/sde/main';
import React from 'react';

import { getStyles } from './PropertyPill.styles';

interface PropertyPillProps extends PillProps {
  isHighlighted?: boolean;
}

export default function PropertyPill({ isHighlighted = false, ...rest }: PropertyPillProps): React.ReactElement {
  const styles = getStyles(theme);

  const highlighted = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  };

  return (
    <Pill
      variant="filled"
      sx={{
        ...styles.pill,
        ...(isHighlighted ? highlighted : null),
      }}
      {...rest}
    />
  );
}

PropertyPill.displayName = 'PropertyPill';
