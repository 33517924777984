import { Box, BoxProps } from '@mui/material';
import React from 'react';

export interface ContentWrapperProps extends BoxProps {
  isColumnFlexBox?: boolean;
  isRowFlexBox?: boolean;
  disableInnerPadding?: boolean;
  children: React.ReactNode;
}

const SECTION_WRAPPER_PADDING = { xs: 5, lg: 6 };
const COLUMN_FLEXBOX_SETTINGS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  justifyItems: 'center',
  justifySelf: 'center',
};

const ROW_FLEXBOX_SETTINGS = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  columnGap: { xs: 3 },
};

export const SectionWrapper = ({
  isColumnFlexBox = false,
  isRowFlexBox = false,
  disableInnerPadding = false,
  children,
  ...rest
}: ContentWrapperProps): JSX.Element => (
  <Box
    data-testid="section-wrapper"
    sx={{
      alignSelf: 'stretch',
      paddingX: disableInnerPadding ? 0 : SECTION_WRAPPER_PADDING,
      ...(isColumnFlexBox ? COLUMN_FLEXBOX_SETTINGS : null),
      ...(isRowFlexBox ? ROW_FLEXBOX_SETTINGS : null),
    }}
    {...rest}
  >
    {children}
  </Box>
);

SectionWrapper.displayName = 'SectionWrapper';
