import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { useCallback, useRef, useState } from 'react';

import { Logger, removeEmptyObjectParams } from '../../utils';
import { UseFetchEstatesProps, UseFetchEstatesReturn } from './useFetchEstates.types';

export const useFetchEstates = ({
  initialQuery,
  initialPage = 0,
  initialEstates = [],
  objectTypesString,
  estatesLimit,
  sortOrder,
}: UseFetchEstatesProps): UseFetchEstatesReturn => {
  const currentPage = useRef(initialPage);
  const [estates, setEstates] = useState(initialEstates);
  const [totalItems, setTotalItems] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  let query = removeEmptyObjectParams(initialQuery);

  const fetchEstates = useCallback(async () => {
    currentPage.current += 1;

    query = {
      ...query,
      ...{ page: currentPage.current },
      ...(objectTypesString && { objectType: objectTypesString }),
      ...(estatesLimit && { limit: estatesLimit }),
      ...(sortOrder && sortOrder),
    };

    try {
      setIsFetching(true);
      const response = await ApiClientProvider.getApiClient().getEstates(query);
      const { estates: newEstates, totalItems: newTotalItems, pageCount } = response;

      setEstates((prevEstates) => [...prevEstates, ...newEstates]);
      setTotalItems(newTotalItems);
      currentPage.current = Math.min(pageCount, currentPage.current);

      setHasMoreResults(currentPage.current < pageCount);
    } catch (e) {
      Logger.error(e);
    } finally {
      setIsFetching(false);
    }
  }, [initialQuery, objectTypesString, estatesLimit, sortOrder]);

  return {
    query,
    currentPage,
    estates,
    setEstates,
    totalItems,
    fetchEstates,
    isFetching,
    hasMoreResults,
  };
};
