import { SxProps } from '@mui/material/styles';
import { theme } from '@portals/core/src/themes/sde/main';

export const styles: Record<string, SxProps> = {
  outerBox: {
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    WebkitOverflowScrolling: 'touch',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    cursor: 'grab',
    pl: { xs: 5, sm: 8, lg: 12 },
    pr: { xs: 5, sm: 8, lg: 12 },
  },
  outerBoxDragging: {
    cursor: 'grabbing',
  },
  innerBox: {
    display: 'flex',
    pl: { xs: 5 },
    pr: { xs: 5 },
  },
  scrollContainer: {
    overflowX: 'auto',
  },
  poiContainer: {
    px: { xs: 6, sm: 8 },
    py: { xs: 5, lg: 6 },
  },
  buttonContainer: {
    display: 'flex',
    gap: 3,
    justifyContent: { lg: 'center' },
  },
  buttonSelected: {
    boxShadow: `inset 0px 0px 0px 2px ${theme.palette.action.focus}`,
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 2px ${theme.palette.action.focus}`,
    },
  },
  button: {
    px: { xs: 5, lg: 6 },
    fontWeight: theme.typography.fontWeightBold,
    '& > span': {
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: 2,
    },
    '& > .MuiSvgIcon-root': {
      margin: 0,
      width: { xs: theme.spacing(5), lg: theme.spacing(6) },
      height: 'auto',
    },
  },
};
