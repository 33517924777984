import { ValidatedProps } from './useDragAndScroll.types';

export const validateProps = ({ scrollSpeed, minDragDistance }: ValidatedProps): void => {
  if (scrollSpeed !== undefined && scrollSpeed < 0) {
    throw new Error('scrollSpeed must not be negative');
  }

  if (minDragDistance !== undefined && minDragDistance < 0) {
    throw new Error('minDragDistance must not be negative');
  }
};
