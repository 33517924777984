import { SxProps, Theme } from '@mui/material';

export const stylesFn = (theme: Theme): Record<string, SxProps> => ({
  outerBox: {
    minWidth: { xs: '320px', md: '704px', lg: '885px' },
    maxWidth: { xs: '431px', md: '100%', lg: '100%' },
    minHeight: { xs: '225px', md: '113px', lg: '177px' },
    width: { xs: '100%', md: '704px', lg: '885px' },
    height: 'auto',
    margin: '0 auto',
    paddingX: {
      xs: theme.spacing(5),
      md: theme.spacing(8),
    },
    paddingBottom: {
      lg: theme.spacing(6),
    },
    marginTop: {
      xs: theme.spacing(9),
      md: theme.spacing(7),
      lg: theme.spacing(6),
    },
    backgroundColor: 'transparent',
  },
  extendFieldsButton: {
    '&& .MuiButton-endIcon > *:nth-of-type(1)': { fontSize: 'inherit' },
  },
  zipCityAutocompleteBox: {
    position: 'relative',
    '.MuiAutocomplete-root .MuiAutocomplete-input': { marginRight: '25%' },
    '.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline': { borderRight: 'none' },
    mr: { md: 3 },
    mb: { xs: 5, md: 0 },
  },
  customSelectBox: {
    position: 'absolute',
    top: '0',
    right: '0',
    width: { xs: '40%', md: '27%', lg: '27%' },
  },
  customSelectStyles: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    '&& .MuiSelect-select': {
      minHeight: 'inherit',
    },
    '.MuiSelect-icon': {
      padding: 0,
      pointerEvents: 'none',
    },
  },
  buttonGroupSelectBox: {
    ml: { md: 3 },
  },
  outerGrid: {
    paddingTop: { xs: 3, md: 2 },
  },
  extendFieldsButtonGridDefault: {
    marginTop: { xs: 4, md: 2 },
    display: { xs: 'inline-grid', md: 'none', lg: 'none' },
  },
  extendFieldsButtonGridHidden: {
    display: 'none',
  },
  extendedFieldsGridDefault: {
    paddingRight: { md: 5, lg: 5 },
    gridTemplateColumns: '100%',
    '.MuiInputLabel-shrink': { top: theme.spacing(2) },
    display: {
      xs: 'none',
      md: 'inherit',
      lg: 'inherit',
    },
    '.MuiGrid-item': {
      width: 0,
    },
  },
  extendedFieldsGridExtended: {
    display: {
      xs: 'inline-grid',
    },
    '.MuiGrid-item': {
      width: 'auto',
    },
  },
  hitButtonGrid: {
    marginTop: { xs: 4, md: 2, lg: 2 },
  },
});
