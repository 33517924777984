import {
  Box,
  MenuItem,
  ToggleButton as MUIToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { CheckCircleOutline } from '@portals/icons';
import { map } from 'lodash-es';
import React, { useCallback } from 'react';

export interface ButtonOptionsProps {
  value: any;
  label: string;
}

export interface SelectOptionsProps {
  value: any;
  label: string;
}

export interface Props {
  buttonOptions: ButtonOptionsProps[];
  selectOptions: SelectOptionsProps[];
  buttonValue: any;
  selectValue: any;
  highlightSelectedOption: boolean;
  onButtonClick?: (value) => boolean;
  onSelectClick?: (value) => boolean;
}

const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(5),
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey['300'],
  },
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: theme.palette.green.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));

export const ButtonGroupSelect: React.FunctionComponent<Props> = ({
  buttonOptions,
  selectOptions,
  buttonValue,
  selectValue,
  highlightSelectedOption,
  onButtonClick = () => {
    return false;
  },
  onSelectClick = () => {
    return false;
  },
}: Props) => {
  const theme = useTheme();

  const handleSelectClick = (event, value) => {
    onSelectClick(value);
  };

  const handleToggleButtonClick = useCallback(
    (event, value) => {
      if (value !== null) {
        onButtonClick(value);
      }
    },
    [onButtonClick]
  );

  return (
    <>
      <Box py={4} px={5}>
        <ToggleButtonGroup value={buttonValue} fullWidth exclusive onChange={handleToggleButtonClick}>
          {map(buttonOptions, (option) => (
            <ToggleButton disableRipple key={option.value} value={option.value}>
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      {map(selectOptions, (option) => (
        <Box key={option.value}>
          <Divider />
          <MenuItem
            disableRipple
            className={highlightSelectedOption && selectValue === option.value ? 'Mui-selected' : 'unselected'}
            /* eslint-disable-next-line react/jsx-no-bind */
            onClick={(event) => handleSelectClick(event, option.value)}
            sx={{
              paddingTop: theme.spacing(4),
              paddingBottom: theme.spacing(4),
              display: 'flex',
              justifyContent: 'space-between',
              color: 'rgb(68, 68, 68)',
            }}
          >
            <Typography variant="button">{option.label}</Typography>
            {highlightSelectedOption && selectValue === option.value && <CheckCircleOutline display="inline-flex" />}
          </MenuItem>
        </Box>
      ))}
    </>
  );
};

ButtonGroupSelect.displayName = 'ButtonGroupSelect';
