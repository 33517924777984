import { Box, Typography, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import type { Theme } from '@portals/core/src/themes/themes';
import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React from 'react';

import { useEstateListHeadingFormatter } from '../../../hooks';
import { HeartWithSearch } from '../../../icons';

interface HeaderProps {
  searchParams: EstateSearchProps;
  totalItems: number;
}

type SaveSearchDisabled = {
  saveSearchButtonLabel?: string;
  onClickSaveSearch?: () => void;
  isSaveSearchButtonDisabled: true;
};

type SaveSearchEnabled = {
  saveSearchButtonLabel: string;
  onClickSaveSearch: () => void;
  isSaveSearchButtonDisabled: false;
};

type SaveSearchProps = SaveSearchDisabled | SaveSearchEnabled;

type Props = HeaderProps & SaveSearchProps;

export const Headline = ({
  searchParams,
  totalItems,
  onClickSaveSearch,
  saveSearchButtonLabel,
  isSaveSearchButtonDisabled,
}: Props): React.ReactElement => {
  const theme = useTheme<Theme>();
  const headingFormatter = useEstateListHeadingFormatter();
  return (
    <Box className="estate-list-headline-container" display="flex" alignItems="center" mb={{ xs: 5, md: 7, lg: 7 }}>
      <Box
        className="estate-list-headline"
        pl={{
          xs: 0,
          md: 0,
          text: 6,
          lg: 6,
        }}
        pr={2}
      >
        <Typography variant="h2" mb={0}>
          {headingFormatter(searchParams, totalItems)}
        </Typography>
      </Box>
      <Box
        display={{
          xs: 'none',
          md: 'none',
          text: 'inherit',
          lg: 'inherit',
        }}
        whiteSpace="nowrap"
      >
        {!isSaveSearchButtonDisabled && (
          <Button
            color="inherit"
            size="small"
            endIcon={<HeartWithSearch />}
            sx={{
              color: theme.palette.primary.main,
            }}
            onClick={onClickSaveSearch}
          >
            {saveSearchButtonLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};

Headline.displayName = 'Headline';
