export type Option = {
  value: string;
  label: string;
};

export enum HasParkingSpacesState {
  YES = 'yes',
  NO = 'no',
}

export enum NumberSelectOptions {
  NUMBER_FLOORS = 10,
  NUMBER_PARKING_SPACES = 20,
}

export enum AvailablenessState {
  BY_ARRANGEMENT = 'by_arrangement',
}
