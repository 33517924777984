import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  const label = {
    textAlign: 'center',
    position: 'absolute',
    top: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      top: theme.spacing(1),
    },
    'b span': {
      display: 'block',
      minWidth: '55px',
      fontSize: '8px',
      [theme.breakpoints.up('lg')]: {
        minWidth: '140px',
        fontSize: 'initial',
      },
    },
  };

  const marker = {
    boxSizing: 'initial',
    position: 'absolute',
    top: `-${theme.spacing(1)}`,
    width: '4px',
    height: '30.5px',
    [theme.breakpoints.up('lg')]: {
      top: `-${theme.spacing(2)}`,
      width: '10px',
      height: '42.5px',
    },
  };

  const stroke = {
    width: '100%',
    height: '100%',
  };

  return {
    label,
    marker,
    stroke,
  };
};
