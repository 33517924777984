import type { SxProps } from '@mui/system';

export const styles: Record<string, SxProps> = {
  gridContainer: {
    mt: { xs: 0, md: 0, lg: 0 },
    width: '100%',
  },
  gridContainerEstateList: {
    mt: { xs: 0, md: 0, lg: 0 },
    marginBottom: 0,
    px: { xs: 0, md: 0, lg: 0 },
    width: '100%',
  },
  gridItemPlainList: {
    mt: { xs: 6, md: 6, text: 0, lg: 0 },
    mb: 8,
    gridColumnStart: {
      text: 1,
      lg: 1,
    },
    gridColumnEnd: {
      text: 9,
      lg: 9,
    },
    width: '100%',
  },
  gridItemEstateList: {
    mt: 0,
    width: { xs: '100%', lg: '800px' },
    mb: 8,
    gridColumnStart: {
      text: 1,
      lg: 1,
    },
    gridColumnEnd: {
      text: 9,
      lg: 9,
    },
  },
  saveSearchButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  saveSearchButtonLabel: {
    pointerEvents: 'auto',
    pr: 2,
  },
  loadingControlContainer: {
    width: 1,
    display: 'flex',
    justifyContent: 'center',
    py: 5,
  },
};
