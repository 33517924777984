import { SxProps, Theme } from '@mui/material';

export const stylesFn = (theme: Theme): Record<string, SxProps<Theme>> => ({
  inputLabel: {
    top: '3px',
  },
  select: {
    fontSize: { xs: 13, lg: 16 },
    '& .MuiSelect-icon': {
      fontSize: 'inherit',
      marginRight: { xs: theme.spacing(4), md: theme.spacing(5) },
      right: 0,
    },
    '&& .MuiSelect-select.MuiInput-input:focus': {
      background: 'transparent',
    },
    '::after': {
      borderBottomColor: theme.palette.primary.dark,
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingX: {
      xs: theme.spacing(4),
      lg: theme.spacing(5),
    },
  },
  hiddenMenuItem: {
    display: 'none',
  },
  customInputMenuItem: {
    display: 'flex',
  },
});
