import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

export const Subtitle: React.FC<{ subtitle: string }> = ({ subtitle }) => {
  return (
    <Typography
      data-testid="estate-card-subtitle"
      variant="h6"
      component="h6"
      sx={{ marginBottom: { xs: 0, sm: 2, lg: 4 } }}
    >
      {subtitle}
    </Typography>
  );
};

Subtitle.displayName = 'Subtitle';
